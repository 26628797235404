$white: #fff;
$itinerary-orange: #f15a29;
$black: #000;
$itinerary-dark-gray: #1aad9b;
$itinerary-light-gray: #e7f7f6;
$itinerary-orange-border: #f00;
$itinerary-light-pink: #FCEAE0;
$itinerary-light: #d6d9da;
$itinerary-header-light: #E9F6F9;

@font-face {
    font-family: 'Montserrat SemiBold';
    src: url('../../assets/fonts/font-itinerary/Montserrat-SemiBold.otf') format( 'opentype' );
}
@font-face {
    font-family: 'Montserrat ExtraBold';
    src: url('../../assets//fonts//font-itinerary/Montserrat-ExtraBold.otf') format('opentype');
}
@font-face {
    font-family: 'Montserrat Regular';
    src: url('../../assets//fonts//font-itinerary/Montserrat-Regular.otf') format('opentype');
}

$montserrat-regular: 'Montserrat Regular';
$montserrat-semi-bold: 'Montserrat SemiBold';
$montserrat-extra-bold: 'Montserrat ExtraBold';
