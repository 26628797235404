.brand-img{
	width:100px;
}
.user-logo{
	width:15px;	
}

.notification-container {
  width: 100% !important;
  padding: 0 !important;
  .notification {
    &::before {
      display: none;
    }
    margin: 0;
    padding: 20px;
    .notification-message {
      text-align: center;
    }
  }
}

/*-------------------
    Page Loader
--------------------*/

.page-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  top: 58px;
  background: rgba(255, 255, 255, 0.88);
  width: calc(100% - 240px);
  z-index: 999999999;
  .loader-circle {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    &:after {
      content: " ";
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid #2cb7a9;
      border-color: #2cb7a9 transparent #2cb7a9 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.loader-circle {
    width: 30px;
    height: 30px;
    &:after {
      content: " ";
      display: block;
      width: 26px;
      height: 26px;
      margin: 1px;
      border-radius: 50%;
      border: 2px solid #2cb7a9;
      border-color: #2cb7a9 transparent #2cb7a9 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
 
.pagination{
	button{
		line-height: initial;
	}
}
.sair-wrapper
.sair-nav{
	.navbar-nav {
		li{
			a{
				cursor: pointer;
				&.active{
					background: #34bdb0;
    				color: #fff !important;
				}
			}
			.ion-ios-arrow-down{
			    transition: all 0.5s ease;
			}
			&.toggle-active {
				.ion-ios-arrow-down {
					// transform: rotate(180deg);
				}
			}
		}
		
	}
}

.sub-category{
	li {
		a{
			padding: 0.25rem 1rem 0.25rem 3rem;
			font-size: 0.9rem;
			color: rgba(50, 65, 72, 0.6) !important;
			width: 100%;
			display: flex;
		}
	}
}

.toggle3{
	height: 25px; 
	width: 125px;
	.toggle-slide{
		    border-radius: 9999px;
			overflow: hidden;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			direction: ltr;
			text-align: center;
			
		.toggle-inner{
			width: 125px; 
			margin-left: 0px; 
		}
		.toggle-on{
			height: 25px; 
			width: 62.5px; 
			line-height: 25px;
			float: left;font-size: 11px;
    		font-weight: 500;
		}
		.toggle-off{
			height: 25px; 
			width: 62.5px; 
			line-height: 25px;
			float: left;
			font-size: 11px;
    		font-weight: 500;
		}
		
		.toggle-blob{
			height: 25px;
			width: 25px; 
			margin-left: -12.5px;
			display: none;
			float: left;
			font-size: 11px;
    		font-weight: 500;
		}
	}
	
}

.filter-dropdown {
	.dropdown-toggle-link {
		background: #f3f6f9;
		border: 1px solid #dfe3e9;
		border-radius: 4px;
		padding: 0.6rem 0.6rem 0.55rem 0.6rem;
	}
}
// .normal-modal{
// 	position: fixed;
//     top: 0px;
//     right: 0px;
//     background: #e1e1e1;
//     z-index: 9999;
//     height: 100%;
//     overflow: auto;
//     padding: 1rem;
//     width: 30%;
// 	transition: 1s ease-in-out;
// 	transform: translateX(0%);
// }

.portal {
  .component-selection-wrapper {
    height: calc(100% - 19px);
    width: 100%;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
	z-index: 999999;
    .edit-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 500px;
      background: #fff;
      transform: translateX(100vw);
      transition: 0.5s;
      right: 0;
      z-index: 999;
      &.lg {
        width: 650px;
      }
      &.sm {
        width: 350px;
      }
    }
  }
  &.edit-section-open {
    .component-selection-wrapper {
      .edit-overlay {
        transform: translateX(0);
      }
    }

    .slider-overlay {
      opacity: 0.5;
      transition: 0.5s;
    }
  }

  .slider-overlay {
    display: none;
  }
  &.display-overlay {
    .slider-overlay {
      display: block;
    }
  }
}

.slider-close {
  position: absolute;
  right: 10px;
  top: 15px;
}

.error-message{
	width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.was-validated {
	.form-control{
		&:valid{
			border-color: #22af47;
			background-image:url('../images/svg/success.svg');
			background-repeat: no-repeat;
			background-position: center right calc(.375em + .1875rem);
			background-size: calc(.75em + .375rem) calc(.75em + .375rem);
			
		}
		&.inValid{
			border-color: #dc3545;
			background-image:url('../images/svg/close.svg');
			background-repeat: no-repeat;
			background-position: center right calc(.375em + .1875rem);
			background-size: calc(.75em + .375rem) calc(.75em + .375rem);
			&:focus{
				border-color: #dc3545;
			}
		}
		
	}
	 
}
.cursor-pointer{
	cursor: pointer;
}
.slider-header{
	
}
.slider-body{
	height: calc(100vh - 4.5rem);
    overflow-y: auto;
}

.custom-radio-btn{
	label{
		cursor: pointer;
	}
	input {
		&:checked ~ .checkmark {
			background-color: #00acf0;
			border-color: #00acf0;
			&:after {
				display: block;
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 3px;
		left: 0;
		height: 18px;
		width: 18px;
		border: 2px solid #666666;
		border-radius: 50px;
    &:after {
     	content: "";
		position: absolute;
		display: none;
		left: 4px;
		top: 4px;
		width: 5px;
		height: 5px;
		border-radius: 50px;
		border: solid white;
    }
  }
}

.custom-checkbox{
	label{
		cursor: pointer;
	}
	input {
		&:checked ~ .checkmark {
			background-color: #00acf0;
			border-color: #00acf0;
			&:after {
				display: block;
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 3px;
		left: 0;
		height: 18px;
		width: 18px;
		border: 2px solid #666666;
		border-radius: 2px;
    &:after {
     	content: "";
		position: absolute;
		display: none;
		left: 5px;
		top: 1px;
		width: 5px;
		height: 10px; 
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
    }
  }
}

.filepreview{
	width: 100px;
    height: 100px;
    display: flex;
    margin: auto;
	margin-bottom: 1rem;
}
.activity-banner{
	height:300px;
	background-size: cover;
	
}
.sair-Features h2 {
  font-family: "Merriweather", serif;
  color: #67686B; }

.sair-Features .sair-features-list li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 30px;
  color: #67686B;
  font-size: 0.9rem; }

.sair-Features ul.sair-features-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.sair-Features ul.includeList {
  padding-left: 1rem;
  font-size: 0.9rem;
  color: gray;
  list-style: disc;
   }
  .sair-Features ul.includeList li {
    padding-bottom: 0.69rem; }

.sair-features-list li:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  content: "";
  position: absolute;
  left: 0; }


.list-img {
	width: 100px;
	display: block;
}

.card-block {
	.card {
		.card-header {
			font-weight: 700;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
		b {
			font-weight: 700;
		}
	}
}

.itinerary-section {
	.required {
		color:red;
    }
	.collapsable-itinerary {
		.card-header {
			background-color: #ced4da;
			padding: 0.5rem !important;
		}
        i {
            transition: all 0.5s ease;
            line-height: 25px;
            min-width: 25px;
            font-weight: bold;
            font-size: 24px;
        }
        .add-accordin {
            float: right;
            transition: all 0.5s ease;
            background: transparent;
            color: black;
            border: none;
            outline:none;
        }
        .itinerary-window {
            border: 1px solid #ced4da;
            padding: 10px;
        }
	}
}