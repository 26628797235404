@import './itinerary-color.scss';

.justify-itinerary{
    text-align: justify
}
.header-bg{
    background-color: $itinerary-light-pink;
    width: 725px;
    height: 120px;
    margin-top: 10px;
}
.header-txt{
    padding-top: 5px;
    font-family: Montserrat ExtraBold;
    color: #f15a29;
    font-size: 1.8rem;
    line-height: initial;
}
.header-sub-txt{
    padding-top: 5px;
    font-family: $montserrat-semi-bold;
    color: $black;
    font-size: 1rem;
}
.header-sub-txt-2{
    color: $black;
    font-size: 0.8rem;
    font-family: $montserrat-regular;
}
.header-2-bg{
    background-color: $itinerary-header-light;
    margin-top: 20px;
    width: 725px;
    height: 120px;
}
.header-2-txt{
    padding-top: 5px;
    padding-bottom: 5px;
    color: $itinerary-orange;
    font-family: $montserrat-extra-bold;
    font-size: 1.2rem;
}
.itinerary-bg{
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
    width: 116px;
}
.itinerary-bg-2{
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
    width: 224px;
}
.itinerary-text{
    text-align: right;
    font-family: $montserrat-extra-bold;
    font-size: 1.2rem;
}
.itinerary-text-2{
    text-align: left;
    font-family: $montserrat-semi-bold;
    font-size: 1.2rem;
}
.itinerary-sub-text{
    font-family: $montserrat-regular;
    font-size: 0.8rem;
}
.itinerary-sub-text-left{
    font-family: $montserrat-regular;
    font-size: 0.8rem;
    text-align: left;
}
.city-sub-txt{
    text-align: right;
    font-size: 0.8rem;
    font-family: $montserrat-regular;
}
.suggested-activities{
    padding: 20px;
    background-color: $itinerary-orange;
    margin-right: 80px;
    h3{
        color: $white;
    }    
}
.ref-txt{
    font-family: $montserrat-regular;
    text-align: right;
    padding-right: 20px;
}
.custom-sair-brand .ref-txt{
    color: $white;
    font-family: $montserrat-semi-bold;
}
.itinerary-content {
    max-width: 98%;
    width: 100%;
    position: absolute;
    z-index: 2;
}
.itinerary-base {
    position: relative;
    width: 100%;
    min-height: 100px;
    background-color: $white;
    border-radius: 15px;
    margin: 0 auto;
}
@media (min-width: 768px){
    .hide-in-desktop{
        display: none;
    }
}
.bg-none{
    background-color: none !important;
}
// .itinerary-node-2{
//     margin-top: 4.25rem;
//     margin-left: 30px;
// }
.itinerary-node{
    //margin-left: 30px;
}
.popup-buttons{
    position: fixed;
    right: 10px;
}
.btn-right{
    text-align: right;
}
.itin-img-background {
    background-color: $itinerary-dark-gray;
    padding: 1rem;
    width: 84%;
    .light-bg-div {
        background-color: $itinerary-light-gray;
    }
    .itin-img-wrap {
        background-color: $white;
        display: block;
        padding: 0.625rem;
    }
    .itin-img-header {
        display: inline-block;
    }
    .background-img-main {
        background-size: cover;
        width: 100%;
        height:400px;
        background-repeat: no-repeat;
        margin-bottom: 1rem;
    }
    .sectionHeading {
        background-color: $itinerary-dark-gray;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .custom-travel-section {
        margin: 0 auto;
    }
    .custom-itin-heading {
        padding: 2rem;
        border-radius: 1.5rem;
        display: inline-block;            
        .custom-package-name {
            font-family: 'Poppins', sans-serif;
            float: left;
            h1 {
                color: $itinerary-orange;
                font-weight: 800;
            }
            h3 {
                color: $black;
            }
        }
        .custom-package-details {
            float:right;
            h1 {
                color: $itinerary-dark-gray;
                font-weight: 800;
            }
        }
    }
    .custom-sair-brand {
        h2 {
            font-weight: 800;
            color: $black;
        }
        left: 43%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 4%;
        text-align: center;
        img {
            width: 300px;
        }
    }

    .inclusion-details {
        border: 2px solid $itinerary-orange;
        margin-bottom: 2rem;
        text-align: center;
    }
    
    .inclusion-name {
        background-color: $itinerary-dark-gray;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        color: $white;
        width: 30%;
        position: absolute;
        left: 35%;
        bottom: 97%;
        font-family: $montserrat-semi-bold;
    }
    
    .inclusion-inner-content {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-weight: 800;
        font-family: 'Merriweather', serif;
        .content-custom-inclusion {
            span {
                color: $itinerary-orange;
            }
            h4 {
                color: $black;
            }
        }
    }   
    .custom-pack-image {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        .col-sm-5 {
            .img-row-right {
                margin-bottom: 0.5rem;
                .col-md-6 {
                    padding-left: 0;
                    padding-right: 0; 
                }
            }
            .sec-row {
                margin-right: 0px !important;
                border: 2px solid $itinerary-dark-gray;
                .col-md-6 {
                    padding: 0.2rem 0.1rem 0 0.2rem;
                }
                img {
                    height: 221px;
                    width: 221px;
                }
            }
        }
        .col-sm-6 {
            padding-right: 0; 
            padding-left: 0px;
            .left-img {
                height: 98%;
                width: 99%;
            }
        }
        .first-img {
            height: 96%;
            width: 49% !important;
            margin-left: 5px;
        }
        .first-img-2 {
            height: 96% !important;
            width: 48% !important;
            margin-right: 0px;
            margin-left: 7px;
        }
    }
}
.display-flex{
    display: flex !important;
}
.sec-row{
    margin-right: 0px !important
}
.img-row-right{
    margin-right: 0px !important
}
.loaderCmp{
    display: inline;
    p{
      padding-left: 10px;
      margin: 0;
    }
}
.bg-light-gray{
    background-color: $itinerary-light !important;
}
.download-img-preview{
    font-size: 2rem;
}
.itinerary-bg-3{
    width: 126px;
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
}
.itinerary-bg-4{
    width: 133px;
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
}
.itinerary-bg-5{
    width: 240px;
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
}

.itinerary-bg-6{
    width: 260px;
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
}
.itinerary-bg-7{
    width: 225px;
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
}
.itinerary-bg-8{
    width: 112px;
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
}
.itinerary-bg-9{
    width: 315px;
    background-color: $itinerary-orange;
    padding: 10px;
    color: $white;
    font-size: 1rem;
    font-family: $montserrat-extra-bold;
}
.check-info{
    position: absolute;
    right: 35px;
    z-index: 999;
    top: -101px;
}
.table-border {
    border: 1px solid $black;
    border-collapse: collapse;
    padding: 5px;
    font-size: 10px;
}
.itinerary-table{
    margin-left: 20px;
    margin-top: 20px;
}
.img-header{
    font-family: $montserrat-extra-bold;
    font-size: 4rem;
    text-shadow: 2px 2px $black;
    color: $white;
}
.header-2-txt-hotel{
    text-align: right;
    margin-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $itinerary-orange;
    font-family: $montserrat-extra-bold;
    font-size: 1.2rem;
}
.circle-list{
    border-radius: 50px/50px;
    border: solid 21px $itinerary-orange-border;
    width: 50px;
    height: 50px;
}
.dot {
    height: 8px;
    width: 8px;
    border: 1.5px solid $itinerary-orange;
    border-radius: 50%;
    display: inline-block;
}
.border-round {
    padding: 0px 2px 0 2px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}
.day-date{
    font-family: $montserrat-regular;
    font-size: 0.8rem;   
}
.delete-inclusion{
    font-size: 15px !important;
}